import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
import { CompanyService } from '../../../core/services/api/company/company.service';
import { NotesService } from '../../../core/services/api/farm/notes.service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { DataService } from '../../../core/services/websocket/data.service';
import { FapModalComponent } from '../../partials';
import { NavService } from '../../services/nav.service';
import { ObjectModel } from '../../../core/services/api/objects/object.service';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { LocaleService } from '../../../core/services/api/locale/locale.service';
import { EyeSensorsEnum } from './eye-sensor.enum';
import { TypesService } from '../../../core/services/api/types/types.service';
import { NotificationManagerService } from '../../../core/services/notification/notification-manager.service';
import { AttributeService } from '../../../core/services/api/attribute/attribute.service';
import { MatSelect } from '@angular/material/select';

export enum AcquisitionCodes {
    ACQUISITION_LIST = 410,
    ACQUISITION_DETAIL = 415,
    SEND_REQUEST = 412,
    DELETE_ACQUISITION = 420,
}

@Component({
    selector: 'fap-crop-eye-widget',
    templateUrl: './fap-crop-eye-widget.component.html',
    styleUrls: ['./fap-crop-eye-widget.component.scss'],
})
export class FapCropEyeWidgetComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    public widget: WidgetModel;
    @Input() objects: ObjectModel[] = [];
    @Input() unitTypes: UnitTypeModel[] = [];
    @Input() sensors: SensorModel[] = [];
    public selectedWidget;

    public notes: any[];
    public sensor = null;
    @Output()
    public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public updateSensor: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('addEditWidgetModal') addEditWidgetModal: FapModalComponent;
    @ViewChild('notesModal') notesModal: FapModalComponent;
    @ViewChild('schedulerPopup') schedulerPopup: FapModalComponent;
    @ViewChild('schedulerTriggerModal')
    schedulerTriggerModal: FapModalComponent;
    @ViewChild('mys') mys: MatSelect;

    public zoomLevel = 0;

    public images: any[];

    public zoomLevelString = '0x';
    public currentPan = 0;
    public currentIteration = 0;
    public currentFocus = { x: 50, y: 50 };
    public currentObject;
    public currentObjectLength;
    public currentPanLength;
    public currentImageIndex: number;

    public currentImage: any;

    public toggleMain = false;
    public toggleSub = false;
    public personList: any;
    public imageForm: UntypedFormGroup;
    public selectedType: number;
    public showMenu = true;
    public requestIds = [];
    public initialPanValue = 0;
    public initialZoomValue = 0;
    public selectedRequest;
    public eyeData;
    public selected = 0;
    selectedDropdownValue = 0;
    public selectedRecord = null;
    public subscriptions: Array<Subscription> = [];
    public translation = null;
    public automationTranslation = null;
    public zoomLevels = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20,
    ];
    public panLevels = [
        0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225,
        240, 255, 270, 285, 300, 315, 330, 345,
    ];
    public tiltLevels = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];

    public dropdownValue = [];
    public acquisitionList = [];
    public AqLists = [];
    public listLength: number;
    public imageTypes = [
        { id: 20, name: 'Single Image' },
        { id: 30, name: '360° Image' },
    ];

    public batteryLevel = null;
    public actions = null;
    public batteryValue = null;
    public apiUrl = environment.baseBackendUrl;
    public batterysensor = null;
    public batteryData = null;
    public hideCtrls = false;
    public mediaUrl = environment.mediaUrl;
    public translatedNames: any = [];
    @Input() public langString: string;

    public lastValues = [];
    public imageId = null;
    public stateData = null;
    public imageData = null;
    public cameraStatus = null;
    public humidityData = null;
    public progressData = null;
    public triggerData = null;
    public triggerTasks = null;

    public imageSize = null;
    public coords = [];
    public size = null;
    public fromDate = new Date();
    public toDate = new Date();
    public agg = 'day';
    public dataSource: any[] = [];
    public localSensorData: any = [];
    public formType: any = null;
    public sensorData = null;
    public label: string = null;
    public addEditAutomationForm: UntypedFormGroup;
    public automations = [];
    public schedulers = [];
    public automation = null;
    public scheduler = null;
    public currentSensor;
    public automationForm = [];
    public chips = [];
    public addEditSensorInformationForm: UntypedFormGroup;

    @ViewChild('nameInput1', { static: true }) nameInput1: ElementRef;
    @ViewChild('confirmPopup') confirmPopup: FapModalComponent;
    @ViewChild('automationPopup') automationPopup: FapModalComponent;
    @ViewChild('addEditTranslationsPopup')
    public addEditTranslationsPopup: FapModalComponent;
    @ViewChild('addEditAutomationTranslationPopup')
    public addEditAutomationTranslationPopup: FapModalComponent;

    constructor(
        private globalRegistry: GlobalRegistryService,
        public companyService: CompanyService,
        public navService: NavService,
        private fb: UntypedFormBuilder,
        public sensorService: SensorService,
        public toastr: ToastrService,
        public translateService: TranslateService,
        public dataService: DataService,
        public notesService: NotesService,
        public cacheService: CacheResolverService,
        public router: Router,
        private localeService: LocaleService,
        private typesService: TypesService,
        private notificationManager: NotificationManagerService,
        private formBuilder: FormBuilder,
        private attributeService: AttributeService,
        @Inject(LOCALE_ID) private readonly defaultLocaleId: string
    ) {
        console.log(defaultLocaleId);
    }

    public initForm(): void {
        console.log(this.zoomLevelString);
        this.personList = this.globalRegistry.systemData.persons;
        this.imageForm = this.fb.group({
            pan: [
                this.currentIteration ? this.currentIteration : 0,
                [Validators.max(350), Validators.min(0)],
            ],
            tilt: [
                this.currentPan ? this.currentPan : 0,
                [Validators.required, Validators.max(90), Validators.min(0)],
            ],
            zoom: [
                this.zoomLevelString
                    ? +this.zoomLevelString.substring(
                          0,
                          this.zoomLevelString.length - 1
                      )
                    : 0,
                [Validators.required],
            ],
            focus: [{ x: 50, y: 50 }, Validators.required],
            size: [null, Validators.required],
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((s) => {
            s.unsubscribe();
        });
        console.log(this.imageData);
        if(this.images.length) {
          this.notificationManager.unregisterResource(this.images[0].id);
        }
    }

    public restrict(value) {
        // console.log(value.target.value);
        if (value.target.value > 90) {
            const newValue = 90;
            this.imageForm.get('tilt').patchValue(newValue);
        }
    }

    public toggleImageMenu() {
        this.showMenu = !this.showMenu;
    }

    public getLotName(lotId: number = this.widget.objectId): string {
        const lot = this.globalRegistry.systemData.lots.find(
            (lot) => lot.id === lotId
        );
        return lot ? lot.name : '';
    }

    statusChange(list) {
        list.accepted = !list.accepted;
        // console.log(list);
    }

    prevImageClick() {
        // this.slider.prev();
        if (this.currentImageIndex === 0) return;
        this.currentImageIndex = this.currentImageIndex - 1;
        this.currentImage = this.images[this.currentImageIndex];
    }

    nextImageClick() {
        // this.slider.next();
        if (
            !this.images.length ||
            this.currentImageIndex === this.images.length - 1
        )
            return;
        this.currentImageIndex = this.currentImageIndex + 1;

        this.currentImage = this.images[this.currentImageIndex];
    }

    ngOnInit(): void {
        this.initForm();
        this.initAddEditAutomationForm();
        this.notificationManager.startListening();
        this.notificationManager
        .onResourceUpdate()
        .subscribe((batch) => {
          console.log('Component received updates:', batch);

          // Handle updates here (e.g., update UI or data models)
          batch.forEach((message) => {
            console.log('Update for resource:', message.id, message.data);
            console.log(message);
            if(message.data && message.data.params.data.id) {
              if(message.data.params.type === 'Sensor' && (message.operation === 'data_updated' || message.operation === 'changed')) {
                this.selectedDropdownValue = null;
                this.drillData();
              }
            }
          });
        });
        this.images = [];

        this.currentImageIndex = 0;
        this.currentImage = this.images[this.currentImageIndex];

        this.getConfig();
        // this.getAcquisition();
        this.drillData();
        this.listLength = this.dropdownValue?.length;

        this.subscriptions.push(
            this.dataService.getTrigger.subscribe((res) => {
                if (res == true) {
                    console.log(res);
                    this.getData();
                    this.dataService.setTrigger(false);
                    return;
                }
            })
        );
       
        this.translatedNames = this.globalRegistry.systemData.translations;
        this.subscriptions.push(
            this.sensorService.getName.subscribe((res) => {
                if (res) {
                    this.dataSource = [];
                    this.drillData();
                }
            })
        );
    }

    initAddEditAutomationForm() {
        this.addEditAutomationForm = this.formBuilder.group({
            name: [
                this.automation ? this.automation.name : '',
                Validators.required,
            ],
            name_t: [
                this.automation ? this.automation.name_t : '',
                Validators.required,
            ],
            scheduler: [
                this.automation ? this.automation.scheduler : '',
                Validators.required,
            ],
        });
        if (this.automation && this.automation.name_t) {
            this.globalRegistry.systemData.translations.forEach(
                (translation) => {
                    if (translation.id === this.automation.name_t) {
                        this.automationTranslation = translation;
                    }
                }
            );
            this.addEditAutomationForm
                .get('name')
                .setValue(this.getTranslation(this.automation.name_t));
        }
    }

    addEditTranslation(
        label: string,
        inputElement: any,
        translationTarget: any,
        popup: any,
        form: any
    ) {
        console.log(inputElement);
        if (popup === this.addEditAutomationTranslationPopup) {
            console.log('activity modal');
        } else {
            console.log('type modal');
        }
        // if(translationTarget.id === this.activity.id && this.isBaselineActivity) {
        //     return
        // }
        this.label = label;
        const name = this[inputElement].nativeElement.value;
        const type: any = this.convertKeysToCamelCase(translationTarget);
        console.log(name);
        console.log(type);
        if (name === null || name === '' || type.nameT === null) {
            if (inputElement === 'nameInput') {
                this.translation = null;
            } else {
                this.automationTranslation = null;
            }
            popup.showModal();
        } else {
            let found;
            if (inputElement === 'nameInput') {
                found = this.globalRegistry.systemData.translations.find(
                    (translation) => translation.id === type.nameT
                );
            } else {
                found = this.globalRegistry.systemData.translations.find(
                    (translation) => translation.id === type.nameT
                );
            }
            if (!found) {
                if (inputElement === 'nameInput') {
                    this.getTranslationByKey(type.nameT, popup);
                } else {
                    this.getTranslationByKey(type.nameT, popup);
                }
            } else {
                for (const translation of this.globalRegistry.systemData
                    .translations) {
                    if (type && translation.id === type.nameT) {
                        if (inputElement === 'nameInput') {
                            this.translation = translation;
                            console.log(this.translation);
                        } else {
                            this.automationTranslation = translation;
                            console.log(this.automationTranslation);
                        }
                        popup.showModal();
                        return;
                    }
                }

                if (Object.keys(type).length === 0 && name) {
                    const newTranslation = this[form].controls['name_t'].value;
                    for (const translation of this.globalRegistry.systemData
                        .translations) {
                        if (translation.id === newTranslation) {
                            if (inputElement === 'nameInput') {
                                this.translation = translation;
                                console.log(this.translation);
                            } else {
                                this.automationTranslation = translation;
                                console.log(this.automationTranslation);
                            }
                            popup.showModal();
                            return;
                        }
                    }
                }
            }
        }
    }

    getTranslationByKey(key, popup) {
        this.subscriptions.push(
            this.typesService.getTranslationByKey(key).subscribe((data) => {
                if (data) {
                    if (popup === this.addEditTranslationsPopup) {
                        this.translation = data.body.results;
                        this.addEditTranslationsPopup.showModal();
                    } else {
                        this.automationTranslation = data.body.results;
                        this.addEditAutomationTranslationPopup.showModal();
                    }
                    const index =
                        this.globalRegistry.systemData.translations.findIndex(
                            (item) => item.id === data.body.results.id
                        );
                    if (index !== -1) {
                        this.globalRegistry.systemData.translations[index] =
                            data.body.results;
                        this.globalRegistry.systemData.translationsMap =
                            new Map<number, any[]>();
                        this.translatedNames =
                            this.globalRegistry.systemData.translations;
                        this.createObjectMap(
                            this.globalRegistry.systemData.translations,
                            this.globalRegistry.systemData.translationsMap
                        );
                    } else {
                        this.globalRegistry.systemData.translations.push(
                            data.body.results
                        );
                        this.globalRegistry.systemData.translationsMap =
                            new Map<number, any[]>();
                        this.translatedNames =
                            this.globalRegistry.systemData.translations;
                        this.createObjectMap(
                            this.globalRegistry.systemData.translations,
                            this.globalRegistry.systemData.translationsMap
                        );
                    }
                }
            })
        );
    }

    public createObjectMap(
        arrayOfObjects: any[],
        objectMap: Map<number, any>
    ): Map<number, any> {
        arrayOfObjects.forEach((obj) => {
            objectMap.set(obj.id, obj);
            if (obj.children && obj.children.length) {
                this.createObjectMap(obj.children, objectMap);
            }
        });
        return objectMap;
    }

    getTranslation(translation) {
        const t = this.globalRegistry.systemData.translations.filter(
            (trans) => {
                return trans.id === translation;
            }
        );
        if (t[0]) {
            if (
                t[0][this.langString] === null ||
                t[0][this.langString] === ''
            ) {
                return translation;
            } else {
                return t[0][this.langString];
            }
        } else {
            return translation;
        }
    }

    showModal(widget, pos?: any): void {
        this.initForm();
        this.selectedWidget = widget;
        this.addEditWidgetModal.showModal();
        if (pos) {
            this.imageForm.get('focus').setValue({ x: pos.x, y: pos.y });
        }
    }

    showDataModal(widget): void {
        this.selectedWidget = widget;
        this.notesModal.showModal();
    }

    closeModal(widget): void {
        this.selectedWidget = widget;
        this.confirmPopup.hideModal();
    }

    showConfirmModal(pos) {
        if (pos) {
            this.imageForm.get('focus').setValue({ x: pos.x, y: pos.y });
        }
        this.confirmPopup.showModal();
    }

    removeItem(id) {
        console.log(id);
        const postData = {
            cmd: AcquisitionCodes.DELETE_ACQUISITION,
            sensor: this.sensor,
            params: {},
            id,
        };
        this.subscriptions.push(
            this.sensorService.deleteAcquisition(postData).subscribe(() => {
                this.AqLists = [];
                this.acquisitionList = [];
                this.getAcquisition();
                this.addEditWidgetModal.hideModal();
            })
        );
    }

    showNewAcquisition() {
        console.log(this.stateData);
        this.getFormType(this.stateData.sensor.type.entity_form);
        this.getFormTypes(this.stateData.sensor);
    }

    public getFormType(formTypeId) {
      let url = this.typesService.getUrl('form/' + formTypeId + '/');
      this.cacheService.delete(url);
        this.typesService.getFormTypeById(formTypeId).subscribe((data) => {
            this.formType = data.body.results;
            console.log(this.formType);
        });
    }

    changeType(event) {
        this.selectedType = event.value;
        if (event === 30) {
            this.imageForm.get('pan').patchValue([360, 360]);
        } else {
            this.imageForm.get('pan').patchValue([]);
        }
    }

    toDateTime(secs) {
        //epoch
        const date = new Date(secs * 1000);
        const localTime = date.toLocaleString('en-US');
        return localTime;
    }

    getData() {
        this.subscriptions.push(
            this.dataService.getEyeData.subscribe((res) => {
                this.eyeData = res;
                console.log(res);
                console.log(this.sensor);
                console.log(this.dropdownValue);
                if (
                    this.eyeData.cmd == AcquisitionCodes.ACQUISITION_LIST &&
                    this.eyeData.params.sensor == this.sensor
                ) {
                    console.log(this.eyeData);
                    setTimeout(() => {
                        this.getAcquisition();
                        this.eyeData = null;
                    }, 100);
                    return;
                }
                if (
                    this.eyeData.cmd == AcquisitionCodes.ACQUISITION_DETAIL &&
                    this.eyeData.params.sensor == this.sensor
                ) {
                    setTimeout(() => {
                        if (this.eyeData?.params?.record?.id) {
                            console.log(this.eyeData);
                            if (
                                this.eyeData?.params &&
                                this.selectedRequest.id ==
                                    this.eyeData?.params?.record?.id
                            ) {
                                this.selectedRequest.data =
                                    this.eyeData.params.record.data;
                            } else {
                                const date = new Date(
                                    this.eyeData.params.record.time * 1000
                                );
                                const localTime = date.toLocaleString('en-US');
                                const newData = {
                                    id: this.eyeData.params.record.id,
                                    time: localTime,
                                    images: [],
                                    value: localTime,
                                };
                                this.dropdownValue.unshift(newData);
                                const request = {
                                    id: this.eyeData.params.record.id,
                                    order: this.eyeData.params.state.order,
                                    user: this.eyeData.params.state.user,
                                    accepted:
                                        this.eyeData.params.state.accepted,
                                    time: this.toDateTime(
                                        this.eyeData.params.record.id
                                    ),
                                };
                                this.acquisitionList.unshift(request);
                                this.selectedRequest.data =
                                    this.eyeData.params.record.data;
                            }
                            console.log(this.selectedRequest);
                            if (
                                this.selectedRequest.data[this.zoomLevelString][
                                    this.currentPan
                                ][this.currentIteration]
                            ) {
                                const image = {
                                    image:
                                        this.mediaUrl +
                                        this.selectedRequest.data[
                                            this.zoomLevelString
                                        ][this.currentPan][
                                            this.currentIteration
                                        ],
                                    thumbImage:
                                        this.mediaUrl +
                                        this.selectedRequest.data[
                                            this.zoomLevelString
                                        ][this.currentPan][
                                            this.currentIteration
                                        ],
                                    tilt: this.currentIteration,
                                    pan: this.currentPan,
                                    focus: this.currentFocus,
                                };
                                this.images = [image];
                            } else {
                                this.images = [];
                            }
                            // this.changeDropdown(this.eyeData.params.record.id);
                            this.eyeData = null;
                            return;
                        }
                    }, 1000);
                }
                if (this.eyeData.cmd == 600) {
                    this.toastr.success(this.eyeData.params.info);
                }
                if (
                    this.eyeData.cmd == 500 &&
                    this.eyeData.list[0].sensor == this.batterysensor
                ) {
                    // console.log(this.batteryData);
                    setTimeout(() => {
                        this.batteryValue = this.eyeData.list[2].valueFloat;
                        if (this.batteryValue) {
                            const value =
                                ((this.batteryValue -
                                    this.batteryData.battery.min) *
                                    100) /
                                (this.batteryData.battery.max -
                                    this.batteryData.battery.min);
                            // console.log(value);
                            this.batteryLevel = value.toFixed(0);
                        }
                    }, 100);
                }
            })
        );
        this.dataService.setTrigger(false);
    }

    submit() {
        this.images = [];
        if (this.selectedType === 30) {
            this.imageForm.get('pan').patchValue([360, 360]);
        }
        const postObj = {
            pan: [this.imageForm.get('pan').value],
            zoom: [this.imageForm.get('zoom').value],
            tilt: [this.imageForm.get('tilt').value],
            focus: [this.imageForm.get('focus').value],
            size: [this.imageForm.get('size').value],
        };
        const postData = {
            cmd: AcquisitionCodes.ACQUISITION_DETAIL,
            sensor: this.sensor,
            params: [postObj],
        };
        // this.imageForm.get('zoom').reset();
        // this.imageForm.get('pan').reset();
        // this.imageForm.get('tilt').reset();

        this.subscriptions.push(
            this.sensorService.postAcquisition(postData).subscribe(
                (response) => {
                    console.log(response);
                    this.listLength += 1;
                    this.getAcquisition();
                    const snowball = document.getElementById('sball');
                    snowball.style.display = 'none';
                    this.toastr.success(
                        this.translateService.instant(
                            'widget.CreatedNewAcquistionRequest'
                        )
                    );
                },
                (): void => {
                    this.toastr.error(
                        this.translateService.instant(
                            'widget.failedToCreateNewAcquisition'
                        )
                    );
                }
            )
        );
        // this.addEditWidgetModal.hideModal();
        this.confirmPopup.hideModal();
    }

    showList() {
        this.addEditWidgetModal.showModal();
    }

    getConfig() {
        this.subscriptions.push(
            this.sensorService
                .getAcquisition({
                    cmd: 100,
                    sensor: this.sensor,
                    params: {},
                })
                .subscribe((data) => {
                    console.log(data);
                    if (data.battery) {
                        // (value - min)*100/(max-min)
                        this.batteryData = data;
                        this.batterysensor = data.battery.sensor;
                        if (this.batteryValue) {
                            const value =
                                ((this.batteryValue - data.battery.min) * 100) /
                                (data.battery.max - data.battery.min);
                            // console.log(value);
                            this.batteryLevel = value.toFixed(0);
                        }
                    }
                    if (data.actions) {
                        this.actions = data.actions;
                        // console.log(this.actions);
                    }
                })
        );
    }

    additionalCmd(cmd, id) {
        this.subscriptions.push(
            this.sensorService
                .getAcquisition({
                    cmd: cmd,
                    sensor: this.sensor,
                    id: id,
                    params: {
                        zoom: this.initialZoomValue,
                        pan: this.currentIteration,
                        tilt: this.currentPan,
                        focus: this.currentFocus,
                    },
                })
                .subscribe((data) => {
                    console.log(data);
                })
        );
    }

    onImageClick(event: MouseEvent, widget) {
        const x = event.offsetX;
        const y = event.offsetY;
        const pos = { x, y };
        this.showModal(widget, pos);
    }

    getAcquisition() {
        this.AqLists = [];
        this.images = [];
        this.acquisitionList = [];
        this.dropdownValue = [];
        const params = { filter: 'all' };
        function toDateTime(secs) {
            //epoch
            const date = new Date(secs * 1000);
            const localTime = date.toLocaleString('en-US');
            // const utcTime = date.toUTCString();
            return localTime;
        }
        const url = this.sensorService.getUrl('command/');
        this.cacheService.delete(
            url +
                'cmd=' +
                AcquisitionCodes.ACQUISITION_LIST +
                '&sensor=' +
                this.sensor +
                '&params=' +
                params
        );
        this.subscriptions.push(
            this.sensorService
                .getAcquisition({
                    cmd: AcquisitionCodes.ACQUISITION_LIST,
                    sensor: this.sensor,
                    params: params,
                })
                .subscribe(
                    (data) => {
                        const keys = [];
                        this.dropdownValue = [];
                        for (const key in data) {
                            const request = {
                                id: key,
                                order: data[key].order,
                                user: data[key].user,
                                accepted: data[key].accepted,
                                time: toDateTime(key),
                            };
                            keys.push(key);
                            const dValue = {
                                id: Number(key),
                                value: toDateTime(key),
                                images: [],
                                time: toDateTime(key),
                            };

                            this.acquisitionList.push(request);
                            this.dropdownValue.push(dValue);
                            this.listLength = this.dropdownValue.length;
                            this.requestIds.push(key);
                        }
                        this.dropdownValue.reverse();
                        this.acquisitionList.reverse();
                        console.log(this.acquisitionList);
                        if (this.acquisitionList.length) {
                            this.changeDropdown(this.acquisitionList[0].id);
                        }
                        this.eyeData = null;
                        return;
                    },
                    (error): void => {
                        console.log(error);
                        this.toastr.error(
                            this.translateService.instant(
                                'Failed to fetch acquisition'
                            )
                        );
                    }
                )
        );
    }

    hideMain() {
        this.toggleMain = false;
    }

    toggleMenu() {
        this.toggleMain = !this.toggleMain;
    }

    getFormattedDate(time): string {
        const formattedDate = moment(time, 'M/D/YYYY, h:mm:ss A').format(
            'DD MMM YYYY HH:mm'
        );
        return formattedDate;
    }

    public zoomInClick() {
        this.images = [];
        this.initialPanValue = 0;
        this.currentPan = 0;
        this.initialZoomValue = this.initialZoomValue + 1;

        this.zoomLevelString = Object.keys(this.selectedRequest.data)[
            this.initialZoomValue
        ];

        this.currentObject = this.selectedRequest.data[this.zoomLevelString];
        this.currentPan = Number(Object.keys(this.currentObject)[0]);
        this.currentPanLength = Object.keys(
            this.currentObject[this.currentPan]
        ).length;

        this.currentIteration = Number(
            Object.keys(this.currentObject[this.currentPan])[0]
        );
        if (
            this.selectedRequest.data[this.zoomLevelString][this.currentPan][
                this.currentIteration
            ]
        ) {
            const image = {
                image:
                    this.mediaUrl +
                    this.selectedRequest.data[this.zoomLevelString][
                        this.currentPan
                    ][this.currentIteration],
                thumbImage:
                    this.mediaUrl +
                    this.selectedRequest.data[this.zoomLevelString][
                        this.currentPan
                    ][this.currentIteration],
                tilt: this.currentIteration,
                pan: this.currentPan,
                focus: this.currentFocus,
            };
            this.images = [image];
        } else {
            this.images = [];
        }
    }

    public zoomOutClick() {
        this.images = [];
        this.initialPanValue = 0;
        this.currentPan = 0;
        this.initialZoomValue = this.initialZoomValue - 1;

        this.zoomLevelString = Object.keys(this.selectedRequest.data)[
            this.initialZoomValue
        ];
        this.currentObject = this.selectedRequest.data[this.zoomLevelString];
        this.currentPan = Number(Object.keys(this.currentObject)[0]);

        this.currentPanLength = Object.keys(
            this.currentObject[this.currentPan]
        ).length;

        this.currentIteration = Number(
            Object.keys(this.currentObject[this.currentPan])[0]
        );
        if (
            this.selectedRequest.data[this.zoomLevelString][this.currentPan][
                this.currentIteration
            ]
        ) {
            const image = {
                image:
                    this.mediaUrl +
                    this.selectedRequest.data[this.zoomLevelString][
                        this.currentPan
                    ][this.currentIteration],
                thumbImage:
                    this.mediaUrl +
                    this.selectedRequest.data[this.zoomLevelString][
                        this.currentPan
                    ][this.currentIteration],
                tilt: this.currentIteration,
                pan: this.currentPan,
                focus: this.currentFocus,
            };
            this.images = [image];
        } else {
            this.images = [];
        }
    }

    public previousNextValue(value) {
        const previousValue = this.selectedDropdownValue;
        if (previousValue === 0) {
            this.selectedDropdownValue = this.dropdownValue[0].id;
        } else {
            const currentIndex = this.dropdownValue.findIndex(
                (x) => x.id === previousValue
            );
            this.selectedDropdownValue =
                this.dropdownValue[
                    value ? currentIndex + 1 : currentIndex - 1
                ].id;
        }
        this.changeDropdown(this.selectedDropdownValue);
    }

    public nextPan() {
        this.images = [];
        this.initialPanValue = this.initialPanValue + 1;
        this.currentIteration = Number(
            Object.keys(this.currentObject[this.currentPan])[
                this.initialPanValue
            ]
        );
        if (
            this.selectedRequest.data[this.zoomLevelString][this.currentPan][
                this.currentIteration
            ]
        ) {
            const image = {
                image:
                    this.mediaUrl +
                    this.selectedRequest.data[this.zoomLevelString][
                        this.currentPan
                    ][this.currentIteration],
                thumbImage:
                    this.mediaUrl +
                    this.selectedRequest.data[this.zoomLevelString][
                        this.currentPan
                    ][this.currentIteration],
                tilt: this.currentIteration,
                pan: this.currentPan,
                focus: this.currentFocus,
            };
            this.images = [image];
        } else {
            this.images = [];
        }

        if (!this.currentIteration) {
            this.currentPan = Number(Object.keys(this.currentObject)[1]);
            this.currentPanLength = Object.keys(
                this.currentObject[this.currentPan]
            ).length;
            if (this.currentPanLength == this.initialPanValue) {
                this.currentPan = Number(Object.keys(this.currentObject)[2]);
            }
            this.currentIteration = Number(
                Object.keys(this.currentObject[this.currentPan])[
                    this.initialPanValue
                ]
            );
            this.initialPanValue = this.initialPanValue + 1;
        }
    }

    public prevPan() {
        this.images = [];
        this.initialPanValue = this.initialPanValue - 1;

        this.currentIteration = Number(
            Object.keys(this.currentObject[this.currentPan])[
                this.initialPanValue
            ]
        );
        if (
            this.selectedRequest.data[this.zoomLevelString][this.currentPan][
                this.currentIteration
            ]
        ) {
            const image = {
                image:
                    this.mediaUrl +
                    this.selectedRequest.data[this.zoomLevelString][
                        this.currentPan
                    ][this.currentIteration],
                thumbImage:
                    this.mediaUrl +
                    this.selectedRequest.data[this.zoomLevelString][
                        this.currentPan
                    ][this.currentIteration],
                tilt: this.currentIteration,
                pan: this.currentPan,
                focus: this.currentFocus,
            };
            this.images = [image];
        } else {
            this.images = [];
        }
    }

    public showImage(img) {
        console.log(img);
    }

    // public async drillData() {
    //   if(this.widget.settings) {
    //     const sensorIds = this.widget["settings"]["sensors"].map(
    //       (sensor) => sensor?.id
    //   );
    //     console.log(sensorIds);
    //     const observables = sensorIds.map((sensorId) =>
    //       this.sensorService.drillData({
    //           sensor: sensorId,
    //           from: this.fromDate,
    //           agg_by: this.agg,
    //           to: this.toDate,
    //       })
    //   );
    //   await new Promise<void>((resolve) => { // Include void as the type argument
    //     combineLatest(observables).subscribe((drilled: any[]) => {
    //         this.dataSource = drilled.map((element) => element[element.length - 1]);
    //         resolve(); // Resolve the promise once dataSource is loaded
    //     });
    //   });
    //     this.subscriptions.push(this.sensorService.drillData(sensorIds.toString()).subscribe((data) => {
    //       this.lastValues = data
    //       const localDict = [...data];
    //       console.log(localDict);

    //     }))
    //   }
    // }

    public async drillData() {
        this.fromDate = new Date();
        this.toDate = new Date();
        this.agg = '';
        this.getFromDate();
        this.getToDate();
        this.getAgg();
        this.dataSource = [];
        const sensorIds = this.widget['settings']['sensors'].map(
            (sensor) => sensor.id
        );
        console.log(sensorIds);
        await new Promise<void>((resolve) => {
            setTimeout(() => {
                this.localSensorData = this.getLocalSensors(
                    this.widget['settings']['sensors']
                );
                console.log(this.localSensorData);
                this.localSensorData.forEach((element) => {
                    this.widget['settings']['sensors'].forEach((s) => {
                        if (element.sensor.id === s.id) {
                            Object.assign(element, { ref: s.ref });
                        }
                    });
                    if (element.ref === EyeSensorsEnum.TRIGGER) {
                        this.triggerData = element;
                        this.getTriggerTask(element.sensor.id);
                    } else if (element.ref === EyeSensorsEnum.STATE) {
                        this.stateData = element;
                    } else if (element.ref === EyeSensorsEnum.BATTERY) {
                        this.batteryData = element;
                        console.log(element);
                    } else if(element.ref === EyeSensorsEnum.IMAGE) {
                      this.getAcquisitionImages(element);
                      this.imageData = element;
                      console.log(element);
                    } else {
                        // this.getImage(element.value_string);
                        console.log(element);
                    }
                });
                resolve();
            }, 1000);
        });
    }

    getPicture(input_by): any {
      if(!input_by) {return }
      let person = this.globalRegistry.systemData.personsMap.get(input_by);
      if(person && person.picture) {
        return this.mediaUrl + person.picture;
      } else {
        return '';
      }
    }

    getPtz(option) {
      return `Pan: ${option.pan ? option.pan : 0}, Tilt: ${option.tilt ? option.tilt : 0}, Zoom: ${option.zoom ? option.zoom : 0}`;
    }

    deleteSensorData(id) {
      this.sensorService.deleteSensorData(id).subscribe(() => {
        this.mys.close();
        this.drillData();
        this.toastr.success(this.translateService.instant('widget.sensorDataDeletedSuccessfully'));
      }, (err) => {
        this.toastr.error(err.error.results.error);
      })
    }

    getAcquisitionImages(element: any) {
        let sensorId = element.sensor.id;
        let params = {
            sensor: sensorId,
            from: this.fromDate,
            agg_by: this.agg,
            to: this.toDate,
            order: 'DESC'
        };
        function toDateTime(secs) {
            //epoch
            const date = new Date(secs * 1000);
            const localTime = date.toLocaleString('en-US');
            // const utcTime = date.toUTCString();
            return localTime;
        }
        let url = this.sensorService.getUrl('drill_data/sensor='+sensorId+'&from='+this.fromDate+'&agg_by='+this.agg+'&to='+this.toDate+'&order=DESC');
        this.cacheService.delete(url);
        this.dropdownValue = [];
        this.subscriptions.push(
            this.sensorService.drillData(params).subscribe((data) => {
                this.images = data.results?.[element.sensor.id];
                this.images.forEach((element) => {
                    const dValue = {
                        id: Number(element.id),
                        images: [element.value_json.data.url],
                        time: toDateTime(element.timestamp),
                        pan: element.value_json.data.pan,
                        tilt: element.value_json.data.tilt,
                        zoom: element.value_json.data.zoom,
                        input: element.input,
                        input_by: element.input_by,
                        picture: this.getPicture(element.input_by)
                    };
                    this.dropdownValue.push(dValue);
                });
                console.log(this.dropdownValue);
                console.log(this.globalRegistry.systemData.persons);
                setTimeout(() => {
                  const index = this.dropdownValue.findIndex(obj => obj.id === this.selectedDropdownValue);
                  if (index !== -1) {
                    this.changeDropdown(this.selectedDropdownValue);
                  } else {
                    const nearestId = this.getNearestId();
                    this.changeDropdown(nearestId);
                  }
                }, 500);
                if (this.images.length) {
                  this.registerOnWs(this.images[0].id, element.sensor.id);
                  console.log(data.results?.[element.sensor.id]);
                }
            })
        );
    }

    getNearestId(): any {
      if (this.dropdownValue.length === 0) {
        return null;
      }
    
      const deletedIndex = this.dropdownValue.findIndex(obj => obj.id === this.selectedDropdownValue);
    
      if (deletedIndex === -1) {
        return this.dropdownValue[0]?.id;
      }
    
      return this.dropdownValue[deletedIndex - 1]?.id || this.dropdownValue[deletedIndex + 1]?.id || this.dropdownValue[0]?.id;
    }

    registerOnWs(pid: number, sensorId?: number) {
      let registerObject = {
        "id": pid,
        "type": "REGISTER_OBJ_NOTIF",
        "method": "REQ",
        "params": {"type":"Sensor","id":sensorId}
      };
      this.notificationManager.registerResource(registerObject);
    }

    getTriggerTask(sensorId) {
        this.sensorService
            .getTriggerTask({ sensor: sensorId })
            .subscribe((data) => {
                console.log(data.body.results);
                this.triggerTasks = data.body.results;
            });
    }

    getFromDate() {
        this.subscriptions.push(
            this.sensorService.getFromDate.subscribe((res) => {
                this.fromDate = res;
            })
        );
    }

    getToDate() {
        this.subscriptions.push(
            this.sensorService.getToDate.subscribe((res) => {
                this.toDate = res;
            })
        );
    }

    getAgg() {
        this.subscriptions.push(
            this.sensorService.getAgg.subscribe((res) => {
                this.agg = res;
            })
        );
    }

    convertKeysToCamelCase(obj) {
        const camelCaseObj = {};
        for (const key in obj) {
            const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) =>
                p1.toUpperCase()
            );
            camelCaseObj[camelCaseKey] =
                obj[key] instanceof Object
                    ? this.convertKeysToCamelCase(obj[key])
                    : obj[key];
        }
        return camelCaseObj;
    }

    getLocalSensors(sensorData: any[]) {
        const objects = Object.values(this.objects);
        const unitTypes = Object.values(this.unitTypes);

        return sensorData
            .map((sensor) => {
                const sensorId = sensor.id;
                const sensorObject = this.sensors.find(
                    (s) => s.id === sensorId
                );
                if (!sensorObject) return null;
                const sensorUnitTypes = unitTypes.find(
                    (unitType) => unitType.id === sensorObject.unitType
                );
                const sensorObjectData = objects.find(
                    (obj) => obj.id === sensorObject.objectId
                );

                return {
                    unitType: sensorUnitTypes,
                    object: sensorObjectData,
                    sensor: sensorObject,
                    css: sensor.css,
                };
            })
            .filter(Boolean);
    }

    public getImage(string) {
        if (string) {
            const arr = string.split('=');
            this.imageId = arr[1];
            this.subscriptions.push(
                this.companyService
                    .getCompanyImage(this.imageId)
                    .subscribe((data) => {
                        // this.imageData = data.model;
                        console.log(data);
                    })
            );
        } else {
            return;
        }
    }

    public changeDropdown(value) {
        console.log(value);
        this.images.forEach((image) => {
            if (image.id == value) {
                this.selectedDropdownValue = image.id;
            }
        });
    }

    getCoords(e) {
        const target = e.target;
        const rect = target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const snowball = document.getElementById('sball');
        snowball.style.display = 'block';
        snowball.style.position = 'absolute';
        snowball.style.left = x - 10 + 'px';
        snowball.style.top = y - 10 + 'px';
        console.log(x, y);
        const pos = { x, y };
        const myImg1 = document.querySelector('.myImg') as HTMLImageElement;
        const realWidth = myImg1.offsetWidth;
        const imageWidth = myImg1.parentElement.clientWidth;
        const realHeight = myImg1.offsetHeight;
        const imageHeight = myImg1.parentElement.clientHeight;
        this.coords = [x, y];
        this.imageSize = [realWidth, realHeight];
        this.size = [imageWidth, imageHeight];
        setTimeout(() => {
            const snowball1 = document.getElementById('sball1');
            snowball1.style.display = 'block';
            snowball1.style.position = 'absolute';
            snowball1.style.left = x - 10 + 'px';
            snowball1.style.top = y - 10 + 'px';
        }, 100);
        this.imageForm
            .get('size')
            .setValue({ width: imageWidth, height: imageHeight });
        // this.showModal(widget, pos)
        this.showConfirmModal(pos);
    }

    getSchedulers() {
        const url = this.sensorService.getUrl('scheduler/');
        this.cacheService.delete(url);
        if (this.currentSensor) {
            this.sensorService.getSchedulers().subscribe((data) => {
                // console.log(data);
                this.schedulers = data.body.results;
            });
        }
    }

    editScheduler(scheduler) {
        // console.log(scheduler);
        this.scheduler = scheduler;
        // this.days = this.scheduler.day;
        this.scheduler.hour = this.scheduler.hour
            ? typeof this.scheduler.hour === 'string'
                ? this.scheduler.hour.split(',')
                : this.scheduler.hour
            : '';
        this.schedulerPopup.showModal();
        // this.initAddEditSchedulerForm();
    }

    deleteScheduler(id) {
        this.sensorService.deleteScheduler(id).subscribe(
            () => {
                this.schedulerPopup.hideModal();
                this.getSchedulers();
            },
            (error) => {
                this.toastr.error(error.error.results.error);
            }
        );
    }

    onAddUpdateTranslation(translation) {
        console.log(translation);
        this.translation = translation;
        this.addEditSensorInformationForm.controls['name_t'].setValue(
            translation.id
        );
        this.addEditSensorInformationForm.controls['name'].setValue(
            translation[this.langString]
        );
        this.translation = translation;
        this.addEditTranslationsPopup.hideModal();
    }

    onAddUpdateAutomationTranslation(translation) {
        console.log(translation);
        this.automationTranslation = translation;
        this.addEditAutomationForm.controls['name_t'].setValue(translation.id);
        this.addEditAutomationForm.controls['name'].setValue(
            translation[this.langString]
        );
        this.automationTranslation = translation;
        this.addEditAutomationTranslationPopup.hideModal();
    }

    submitAutomation() {
        console.log(this.addEditAutomationForm.value);
        const { ...clonedObj } = this.addEditAutomationForm.value;
        // console.log(clonedObj);
        // console.log(this.automationForm);
        for (const key in clonedObj) {
            this.automationForm.forEach((item) => {
                if (String(item.id) === key) {
                    // console.log(item);
                    item.value.input = clonedObj[key];
                    this.attributeService
                        .patchField(item.id, item)
                        .subscribe(() => {
                            // console.log(data);
                            this.automationPopup.hideModal();
                        });
                }
            });
        }
        const body = {
            name_t: this.automationTranslation
                ? this.automationTranslation.id
                : '',
            scheduler: this.addEditAutomationForm.value.scheduler,
        };
        // console.log(this.automation);
        if (this.automation) {
            this.sensorService
                .updateTrigger(this.automation.id, body)
                .subscribe(() => {
                    // console.log(data);
                    this.getAutomation();
                });
        }
    }

    getAutomation() {
        const param = { sensor: this.currentSensor.id };
        const url = this.sensorService.getUrl('trigger/');
        this.cacheService.delete(url + 'sensor=' + this.currentSensor.id);
        if (this.currentSensor) {
            this.sensorService.getTriggers(param).subscribe((data) => {
                console.log(data);
                this.getSchedulers();
                this.automations = data.body.results;
                const itemIds = this.automations.map((item) => item.id);
                this.addEditSensorInformationForm
                    .get('automation')
                    .setValue(itemIds);
                this.chips = itemIds;
            });
        }
    }

    public getFormTypes(item) {
        this.schedulerTriggerModal.showModal();
        this.sensorData = item;
    }

    public onUpdatedForm(form) {
        console.log(form);
        this.schedulerTriggerModal.hideModal();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            Object.prototype.hasOwnProperty.call(changes, 'widget') &&
            this.widget
        ) {
            const sensorList = Object.values(this.widget.settings['sensors']);
            this.sensor = sensorList.find(
                (item) => item['ref'] === '250_120'
            )?.['id'];
            console.log(this.sensor);
        }
    }
}
